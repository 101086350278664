// src/utils/authUtils.js

export const checkLoginTime = () => {
    const loginTime = localStorage.getItem("login_time");
    // console.log("Login time from localStorage:", loginTime);
  
    if (!loginTime) {
      // console.log("No login time found in localStorage.");
      return false;
    }
  
    const currentTime = new Date().getTime();
    // const twoMinutesInMilliseconds = 2 * 60 * 1000; // 2 minutes
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  
    if (currentTime - loginTime > oneDayInMilliseconds) {
      // console.log("Login time expired. Clearing localStorage.");
      localStorage.clear();
      return false;
    }
  
    // console.log("Login time is still valid.");
    return true;
  };
  