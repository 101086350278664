import React, { Suspense, lazy, useEffect } from 'react';
import "../src/components/ProductAi/Homepage/css/home.css"
import { BrowserRouter as Router, Routes, Route, useNavigate,useLocation } from 'react-router-dom';
import PrivateRoute from "./components/Auth/Login/PrivateRoutes";
import { checkLoginTime } from './components/Auth/Login/authUtils';
import Services from "./components/LandingPage/Services";
import ReactGA from 'react-ga4';

const Login = lazy(() => import("./components/Auth/Login/Login"));

const Signup = lazy(() => import("./components/Auth/Register/Signup"));
const Navigation = lazy(() => import("./components/LandingPage/navigation"));
const About = lazy(() => import("./components/LandingPage/About"));
const Footer = lazy(() => import("./components/LandingPage/Footer"));
const AdminLayout = lazy(() => import("./components/ProductAi/Layout/AdminLayout"));
const Features = lazy(() => import("./components/LandingPage/Features"));
const FAQ = lazy(() => import("./components/LandingPage/FAQ"));
const CaseStudy = lazy(() => import("./components/LandingPage/CaseStudy"));
const Chatbot = lazy(() => import("./components/ChatBot/Chatbot"));
const Aiservice = lazy(() => import("./components/LandingPage/Aiservices"));
const ComputerVision = lazy(() => import("./components/LandingPage/Computer-Vision/ComputerVision"));
const Languageservice = lazy(() => import("./components/LandingPage/Language-Service/Languageservice"));
const SpeechService = lazy(() => import('./components/LandingPage/speech/SpeechService'));
const DocumentsStudio = lazy(() => import("./components/LandingPage/Doc/DocumentsStudio"));
const FaceService = lazy(() => import("./components/LandingPage/FaceAI/FaceService"));
const CustomVision = lazy(() => import("./components/LandingPage/Computer-Vision/CustomVision"));
const Chatboat = lazy(() => import("./components/LandingPage/ChatBoat/Chatboat"));
const TranslatorService = lazy(() => import("./components/LandingPage/Translator/TranslatorService"));
const ContainModerator = lazy(() => import("./components/LandingPage/Contain/ContainModerator"));
const Headers = lazy(() => import("./components/LandingPage/Headers"));
const Solution = lazy(() => import("./components/LandingPage/Solution"));
const Pronounce = lazy(() => import("./components/ProductAi/Pages/Translate/Pronounce"))
const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-EF5YJL6WNL'); // Replace with your Measurement ID

    // Track initial page load
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

    // Track page views on route change
    const unlisten = () => {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    };

    return () => {
      unlisten(); // Cleanup the listener on component unmount
    };
  }, [location]);

  useEffect(() => {
    const isLoginTimeValid = checkLoginTime();
    const currentPath = location.pathname;

    // Only redirect to login if the current path is not the login or signup page
    if (!isLoginTimeValid && currentPath !== '/login' && currentPath !== '/signup') {
      localStorage.clear();
      navigate('/login');
    }
  }, [navigate, location]);

  return (
    <div>
      <Suspense fallback={
        <div className="d-flex justify-content-center align-items-center vh-100 bg-dark">
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/admin/*" element={<AdminLayout />} />
          </Route>
          <Route path="/Aiservices" element={<Aiservice />} />
          <Route path="/ComputerVision" element={<ComputerVision />} />
          <Route path="/Languageservice" element={<Languageservice />} />
          <Route path="/SpeechService" element={<SpeechService />} />
          <Route path="/Documents" element={<DocumentsStudio />} />
          <Route path="/FaceService" element={<FaceService />} />
          <Route path="/CustomVision" element={<CustomVision />} />
          <Route path="/ChatBoat" element={<Chatboat />} />
          <Route path="/Translator" element={<TranslatorService />} />
          <Route path="/ContainModerator" element={<ContainModerator />} />
          <Route path="/Headers" element={<Headers />} />
          <Route path="/admin/pronunciation" component={Pronounce} />
        </Routes>
      </Suspense>
    </div>
  );
};

const LandingPage = () => {
  return (
    <div className="nk-app-root bg-darkerk" data-menu-collapse="lg">
      <Suspense fallback={
        <div className="d-flex justify-content-center align-items-center vh-100 bg-dark">
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }>
        <Navigation />
        <main className="nk-pages">
          {/* <Services /> */}
          <About />
          <Features />
          <Solution />
          <CaseStudy />
          <FAQ />
          <Chatbot />
        </main>
        <Footer style={{ position: "fixed", bottom: "0", width: "100%" }} />
      </Suspense>
    </div>
  );
};

export default App;
