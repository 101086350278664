import { Outlet, Navigate } from 'react-router-dom';
import React from 'react'; // You can remove this import if not used directly.

import { checkLoginTime } from './authUtils';

function PrivateRoutes() {
    const token = localStorage.getItem('access_token');
    const isLoginTimeValid = checkLoginTime();

    return token && isLoginTimeValid ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoutes;
